import { makeStyles } from "@mui/styles";

export const useStyle = makeStyles({
  popupContainer: {
    position: "absolute",
    top: "50%",
    left: "50%",
    zIndex: "2",
    "-moz-transform": "translateX(-50%) translateY(-50%)",
    "-webkit-transform": "translateX(-50%) translateY(-50%)",
    transform: "translateX(-50%) translateY(-50%)",

    border: "4px solid #73ff00",
    padding: "0px",
    cursor: "pointer",
    width: "fit-content",
    borderRadius: "20px",
  },

  donateImg: {
    margin: "0px",
    borderRadius: "20px",
    maxWidth: "500px",
    marginBottom: "-4px",
  },

  popupInnerContainer: {
    position: "relative",
    padding: "0px",
  },

  popupCloseBtn: {
    position: "absolute",
    top: "-13px",
    right: "-12px",
    background: "white",
    padding: "0px",
  },

  "@media screen and (max-width: 600px)": {
    donateImg: {
      width: "350px",
    },

    popupCloseBtn: {
      right: "-12px",
      top: "-14px",
    },
  },
});

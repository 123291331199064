export const data = [
  {
    id: 0,
    name: "Assam",
    district: [
      {
        name: "Cachar",
        city: [
          {
            name: "Silchar",
            services: [
              {
                name: "Covid Test",
                link: "https://sites.google.com/view/silchar/covid-test",
              },
              {
                name: "Ambulance Service",
                link: "https://sites.google.com/view/silchar/ambulance",
              },
              {
                name: "Oxygen",
                link: "https://sites.google.com/view/silchar/oxygen",
              },
              {
                name: "Consult Doctor",
                link: "https://docs.google.com/spreadsheets/d/1kBj20UAtAEn8VeNqe_zmnXX4qzEsELra8x1Pns0AD-c/edit#gid=2128813142",
              },
              {
                name: "Medicine",
                link: "https://api.whatsapp.com/send/?phone=919954845436&text&app_absent=0",
              },
              {
                name: "Essentials Delivery",
                link: "https://api.whatsapp.com/send/?phone=919954845436&text&app_absent=0",
              },
              {
                name: "Hospitals",
                link: "https://docs.google.com/spreadsheets/d/1kBj20UAtAEn8VeNqe_zmnXX4qzEsELra8x1Pns0AD-c/edit#gid=516484496",
              },
              {
                name: "Blood",
                link: "https://api.whatsapp.com/send/?phone=919954845436&text&app_absent=0",
              },
              {
                name: "Burial & Cremationals",
                link: "https://api.whatsapp.com/send/?phone=919954845436&text&app_absent=0",
              },
              {
                name: "Sanitization",
                link: "https://sites.google.com/view/silchar/sanitization",
              },
              {
                name: "Air Ambulance",
                link: "https://sites.google.com/view/silchar/air-ambulance",
              },
            ],
          },
        ],
      },

      {
        name: "Karimganj",
        city: [
          {
            name: "Karimganj",
            services: [
              {
                name: "Covid Test",
                link: "https://sites.google.com/view/karimganj/covid-test",
              },
              {
                name: "Oxygen",
                link: "https://sites.google.com/view/karimganj/oxygen",
              },
              {
                name: "Ambulance",
                link: "https://sites.google.com/view/karimganj/ambulance",
              },
              {
                name: "Consult Doctor",
                link: "https://www.quickobook.com/",
              },
              {
                name: "Medice",
                link: "https://sites.google.com/view/karimganj/kxj-exclusive",
              },
              {
                name: "Essentials Delivery",
                link: "https://sites.google.com/view/karimganj/kxj-exclusive",
              },
              {
                name: "Hospitals",
                link: "https://sites.google.com/view/karimganj/kxj-exclusive",
              },
              {
                name: "Blood",
                link: "https://sites.google.com/view/karimganj/kxj-exclusive?authuser=0",
              },
              {
                name: "Air Ambulance",
                link: "https://sites.google.com/view/karimganj/air-ambulance",
              },
              {
                name: "Burial & Cremation",
                link: "https://api.whatsapp.com/send/?phone=919954845436&text&app_absent=0",
              },
              {
                name: "Sanitization",
                link: "https://sites.google.com/view/karimganj/sanitization?authuser=0",
              },
            ],
          },
        ],
      },
      {
        name: "Kamrup Metro",
        city: [
          {
            name: "Guwahati",
            services: [
              {
                name: "Covid Test",
                link: "https://sites.google.com/view/guwahaticovid/covid-test",
              },
              {
                name: "Oxygen",
                link: "https://sites.google.com/view/guwahaticovid/oxygen",
              },
              {
                name: "Ambulance",
                link: "https://sites.google.com/view/guwahaticovid/ambulance",
              },
              {
                name: "Consult Doctor",
                link: "https://sites.google.com/view/guwahaticovid/doctors-for-teleconsultation?authuser=0",
              },
              {
                name: "Medice",
                link: "https://sites.google.com/view/guwahaticovid/medicine-delivery?authuser=0",
              },
              {
                name: "Essentials Delivery",
                link: "https://sites.google.com/view/guwahaticovid/grocery-delivery?authuser=0",
              },
              {
                name: "Hospitals",
                link: "https://api.whatsapp.com/send/?phone=919954845436&text&app_absent=0",
              },
              {
                name: "Blood",
                link: "https://api.whatsapp.com/send/?phone=919954845436&text&app_absent=0",
              },
              {
                name: "Air Ambulance",
                link: "https://sites.google.com/view/guwahaticovid/air-ambulance?authuser=0",
              },
              {
                name: "Burial & Cremation",
                link: "https://sites.google.com/view/guwahaticovid/burial-and-cremation?authuser=0",
              },
              {
                name: "Sanitization",
                link: "https://sites.google.com/view/guwahaticovid/sanitization?authuser=0",
              },
            ],
          },
        ],
      },
      {
        name: "Other",
        city: [
          {
            name: "Other",
            services: [
              {
                name: "All",
                link: "https://sites.google.com/view/ecoalarmist-covid/assam-covid-support/all-assam-districts",
              },
            ],
          },
        ],
      },
    ],
  },

  {
    id: 1,
    name: "Tripura",
    district: [
      {
        name: "All",
        city: [
          {
            name: "All",
            services: [
              {
                name: "Covid Test",
                link: "https://sites.google.com/view/tripuracovid/covid-test?authuser=0",
              },
              {
                name: "Ambulance Service",
                link: "https://sites.google.com/view/tripuracovid/ambulance?authuser=0",
              },
              {
                name: "Oxygen",
                link: "https://sites.google.com/view/tripuracovid/oxygen?authuser=0",
              },
              {
                name: "Consult Doctor",
                link: "https://sites.google.com/view/tripuracovid/doctors-for-teleconsultation?authuser=0",
              },
              {
                name: "Medicine",
                link: "https://sites.google.com/view/tripuracovid/medicine?authuser=0",
              },
              {
                name: "Essentials Delivery",
                link: "https://sites.google.com/view/tripuracovid/essentials-delivery?authuser=0",
              },
              {
                name: "Hospitals",
                link: "https://sites.google.com/view/tripuracovid/hospitals?authuser=0",
              },
              {
                name: "Blood",
                link: "https://sites.google.com/view/tripuracovid/blood?authuser=0",
              },
              {
                name: "Air Ambulance",
                link: "https://api.whatsapp.com/send/?phone=919954845436&text&app_absent=0",
              },
            ],
          },
        ],
      },
    ],
  },

  {
    id: 2,
    name: "Arunachal Pradesh",
    district: [
      {
        name: "All",
        city: [
          {
            name: "All",
            services: [
              {
                name: "Covid Test",
                link: "https://sites.google.com/view/arunachalpradeshcovid/covid-test?authuser=0",
              },
              {
                name: "Ambulance Service",
                link: "https://sites.google.com/view/arunachalpradeshcovid/ambulance?authuser=0",
              },
              {
                name: "Oxygen",
                link: "https://sites.google.com/view/arunachalpradeshcovid/oxygen?authuser=0",
              },
              {
                name: "Essentials Delivery",
                link: "https://sites.google.com/view/arunachalpradeshcovid/grocery?authuser=0",
              },
              {
                name: "Hospitals",
                link: "https://sites.google.com/view/arunachalpradeshcovid/hospital?authuser=0",
              },
            ],
          },
        ],
      },
    ],
  },

  {
    id: 3,
    name: "Sikkim",
    district: [
      {
        name: "All",
        city: [
          {
            name: "All",
            services: [
              {
                name: "Covid Test",
                link: "https://sites.google.com/view/sikkimcovid/helplines?authuser=0",
              },
              {
                name: "Ambulance Service",
                link: "https://sites.google.com/view/sikkimcovid/ambulance?authuser=0",
              },
              {
                name: "Oxygen",
                link: "https://sites.google.com/view/sikkimcovid/helplines?authuser=0",
              },
              {
                name: "Consult Doctor",
                link: "https://sites.google.com/view/sikkimcovid/helplines?authuser=0",
              },
              {
                name: "Medicine",
                link: "https://sites.google.com/view/sikkimcovid/medicine?authuser=0",
              },
              {
                name: "Essentials Delivery",
                link: "https://sites.google.com/view/sikkimcovid/food?authuser=0",
              },
              {
                name: "Hospitals",
                link: "https://sites.google.com/view/sikkimcovid/hospital?authuser=0",
              },
              {
                name: "Blood",
                link: "https://sites.google.com/view/sikkimcovid/helplines?authuser=0",
              },
              {
                name: "Air Ambulance",
                link: "https://sites.google.com/view/sikkimcovid/helplines?authuser=0",
              },
              {
                name: "Burial & Cremation",
                link: "https://sites.google.com/view/sikkimcovid/helplines?authuser=0",
              },
              {
                name: "Sanitization",
                link: "https://sites.google.com/view/sikkimcovid/helplines?authuser=0",
              },
            ],
          },
        ],
      },
    ],
  },

  {
    id: 4,
    name: "Nagaland",
    district: [
      {
        name: "All",
        city: [
          {
            name: "All",
            services: [
              {
                name: "Covid Test",
                link: "https://sites.google.com/view/nagalandcovid/helplines?authuser=0",
              },
              {
                name: "Ambulance Service",
                link: "https://sites.google.com/view/nagalandcovid/ambulance?authuser=0",
              },
              {
                name: "Oxygen",
                link: "https://sites.google.com/view/nagalandcovid/oxygen?authuser=0",
              },
              {
                name: "Consult Doctor",
                link: "https://sites.google.com/view/nagalandcovid/helplines?authuser=0",
              },
              {
                name: "Medicine",
                link: "https://sites.google.com/view/nagalandcovid/helplines?authuser=0",
              },
              {
                name: "Essentials Delivery",
                link: "https://sites.google.com/view/nagalandcovid/food?authuser=0",
              },
              {
                name: "Hospitals",
                link: "https://sites.google.com/view/nagalandcovid/hospital?authuser=0",
              },
              {
                name: "Blood",
                link: "https://sites.google.com/view/nagalandcovid/helplines?authuser=0",
              },
              {
                name: "Air Ambulance",
                link: "https://sites.google.com/view/nagalandcovid/helplines?authuser=0",
              },
              {
                name: "Burial & Cremation",
                link: "https://sites.google.com/view/nagalandcovid/helplines?authuser=0",
              },
              {
                name: "Sanitization",
                link: "https://sites.google.com/view/nagalandcovid/helplines?authuser=0",
              },
            ],
          },
        ],
      },
    ],
  },

  {
    id: 5,
    name: "Mizoram",
    district: [
      {
        name: "All",
        city: [
          {
            name: "All",
            services: [
              {
                name: "Covid Test",
                link: "https://sites.google.com/view/mizoramcovid/helplines?authuser=0",
              },
              {
                name: "Ambulance Service",
                link: "https://sites.google.com/view/mizoramcovid/helplines?authuser=0",
              },
              {
                name: "Oxygen",
                link: "https://sites.google.com/view/mizoramcovid/helplines?authuser=0",
              },
              {
                name: "Consult Doctor",
                link: "https://sites.google.com/view/mizoramcovid/helplines?authuser=0",
              },
              {
                name: "Medicine",
                link: "https://sites.google.com/view/mizoramcovid/helplines?authuser=0",
              },
              {
                name: "Essentials Delivery",
                link: "hhttps://sites.google.com/view/mizoramcovid/helplines?authuser=0",
              },
              {
                name: "Hospitals",
                link: "https://sites.google.com/view/mizoramcovid/hospital?authuser=0",
              },
              {
                name: "Blood",
                link: "https://sites.google.com/view/mizoramcovid/helplines?authuser=0",
              },
              {
                name: "Air Ambulance",
                link: "https://sites.google.com/view/mizoramcovid/helplines?authuser=0",
              },
              {
                name: "Burial & Cremation",
                link: "https://sites.google.com/view/mizoramcovid/helplines?authuser=0",
              },
              {
                name: "Sanitization",
                link: "https://sites.google.com/view/mizoramcovid/helplines?authuser=0",
              },
            ],
          },
        ],
      },
    ],
  },

  {
    id: 6,
    name: "Meghalaya",
    district: [
      {
        name: "All",
        city: [
          {
            name: "All",
            services: [
              {
                name: "Ambulance Service",
                link: "https://sites.google.com/view/meghalayacovid/helplines?authuser=0",
              },
              {
                name: "Oxygen",
                link: "https://sites.google.com/view/meghalayacovid/oxygen?authuser=0",
              },
              {
                name: "Medicine",
                link: "https://sites.google.com/view/meghalayacovid/medicine?authuser=0",
              },
              {
                name: "Essentials Delivery",
                link: "https://sites.google.com/view/meghalayacovid/grocery?authuser=0",
              },
              {
                name: "Food Delivery",
                link: "https://sites.google.com/view/meghalayacovid/food-delivery?authuser=0",
              },
              {
                name: "Hospitals",
                link: "https://sites.google.com/view/meghalayacovid/hospitals?authuser=0",
              },
              {
                name: "Air Ambulance",
                link: "https://api.whatsapp.com/send/?phone=919954845436&text&app_absent=0",
              },
            ],
          },
        ],
      },
    ],
  },

  {
    id: 7,
    name: "Manipur",
    district: [
      {
        name: "All",
        city: [
          {
            name: "All",
            services: [
              {
                name: "Covid Test",
                link: "https://sites.google.com/view/manipurcovid/covid-test?authuser=0",
              },
              {
                name: "Ambulance Service",
                link: "https://sites.google.com/view/meghalayacovid/ambulance?authuser=0",
              },
              {
                name: "Oxygen",
                link: "https://sites.google.com/view/manipurcovid/oxygen?authuser=0",
              },
              {
                name: "Consult Doctor",
                link: "https://sites.google.com/view/manipurcovid/doctors-for-teleconsultation?authuser=0",
              },
              {
                name: "Medicine",
                link: "https://sites.google.com/view/manipurcovid/medicine?authuser=0",
              },
              {
                name: "Essentials Delivery",
                link: "https://sites.google.com/view/manipurcovid/essentials-delivery?authuser=0",
              },
              {
                name: "Air Ambulance",
                link: "https://sites.google.com/view/manipurcovid/air-ambulance?authuser=0",
              },
              {
                name: "Burial & Cremation",
                link: "https://sites.google.com/view/manipurcovid/burial-and-cremation?authuser=0",
              },
              {
                name: "Sanitization",
                link: "https://sites.google.com/view/manipurcovid/sanitization-and-disinfection?authuser=0",
              },
            ],
          },
        ],
      },
    ],
  },
];

import React, { useState } from "react";
import { Container, IconButton } from "@mui/material";

import donate_img from "../../donate_img.png";
import CancelIcon from "@mui/icons-material/Cancel";
import { useStyle } from "./style";

const PopupAd = () => {
  const [visibility, setVisibility] = useState(true);
  const classes = useStyle();

  const handleClick = () => {
    window.open("http://donate.ecoalarmist.com/", "_blank");
  };

  return visibility ? (
    <Container className={classes.popupContainer}>
      <Container className={classes.popupInnerContainer}>
        <IconButton
          className={classes.popupCloseBtn}
          color="error"
          size="large"
          onClick={() => setVisibility(!visibility)}
        >
          <CancelIcon
            className={classes.popupCloseBtnIcon}
            fontSize="inherit"
          />
        </IconButton>
        <img
          onClick={handleClick}
          className={classes.donateImg}
          src={donate_img}
          alt="donate banner"
        />
      </Container>
    </Container>
  ) : (
    ""
  );
};

export default PopupAd;

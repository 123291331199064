import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": {
      transform: "translate(34px, 20px) scale(1)",
      color: "white",

    },
    "& .MuiInputLabel-outlined": {
    },
    "& .MuiInputLabel-root": {
      color: "white",
      zIndex: '0'
    },
  },
  inputRoot: {
    color: "white",
    '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
      paddingLeft: 26,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "white",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "#73ff00",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#0064ff",
    },
    "& .MuiSvgIcon-root": {
      color: "white",
    },
  },
}));

import React, { useEffect, useState } from "react";
import { Grid, Typography, Button, Container } from "@mui/material";

import Input from "../Input/Input";
import { data as stateData } from "../../data";
import { Error, WhatsappRounded } from "@mui/icons-material";

const FindResponse = () => {
  const [state, setState] = useState(null);
  const [district, setDistrict] = useState(null);
  const [city, setCity] = useState(null);
  const [service, setService] = useState(null);
  const [link, setLink] = useState(null);

  const [districtData, setDistrictData] = useState([]);
  const [cityData, setCityData] = useState([]);
  const [serviceData, setServiceData] = useState([]);

  const handleButtonClick = () => {
    if (state && district && city && service) window.open(link, "_blank");
    else alert("Please select all the options first");
  };

  const handleWhatsappButtonClick = () => {
    window.open(
      "https://api.whatsapp.com/send/?phone=919954845436&text&app_absent=0",
      "_blank"
    );
  };

  useEffect(() => {
    if (state !== null) {
      const currState = stateData.find((x) => x.name === state);
      setDistrictData(currState.district);
    } else setDistrictData([]);

    setDistrict(null);
    setCityData([]);
    setCity(null);
    setServiceData([]);
    setService(null);
  }, [state]);

  useEffect(() => {
    if (district !== null) {
      const currDistrict = districtData.find((x) => x.name === district);
      setCityData(currDistrict.city);
    } else setCityData([]);

    setCity(null);
    setService(null);
    setServiceData([]);
  }, [district]);

  useEffect(() => {
    if (city !== null) {
      const currCity = cityData.find((x) => x.name === city);
      setServiceData(currCity.services);
    } else setServiceData([]);

    setService(null);
  }, [city]);

  useEffect(() => {
    if (service !== null) {
      const currService = serviceData.find((x) => x.name === service);
      setLink(currService.link);
    }
  }, [service]);

  return (
    <Container
      sx={{
        marginTop: { lg: "2rem", md: "2rem", sm: "1rem", xs: "1rem" },
        paddingBottom: "15px",
      }}
    >
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        gap={"45px"}
      >
        <Typography
          sx={{
            fontSize: {
              lg: 75,
              md: 60,
              sm: 40,
              xs: 30,
            },
          }}
          color={"#FFF"}
          variant="h2"
        >
          NE India's Covid Portal
        </Typography>

        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          gap={"15px"}
        >
          <Input
            label="State"
            data={stateData}
            state={state}
            setState={setState}
          />
          <Input
            label="District"
            data={districtData}
            state={district}
            setState={setDistrict}
          />
          <Input label="City" data={cityData} state={city} setState={setCity} />

          <Input
            label="Service"
            data={serviceData}
            state={service}
            setState={setService}
          />

          <Button
            variant="contained"
            onClick={handleButtonClick}
            sx={{
              backgroundColor: "#0064ff",
              padding: "15px 30px",
              width: {
                lg: "30%",
                md: "40%",
                sm: "70%",
                xs: "80%",
              },
            }}
          >
            Search
          </Button>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            gap="8px"
            sx={{
              width: {
                lg: "30%",
                md: "40%",
                sm: "70%",
                xs: "80%",
              },
              gap: "8px",
            }}
          >
            <Button
              sx={{ flexGrow: "1", borderColor: '#73ff00' , color: '#73ff00' }}
              onClick={() =>
                window.open(
                  "https://sites.google.com/view/ecoalarmist-covid/mental-health-helplines",
                  "_blank"
                )
              }
              variant="outlined"
            >
              Mental Health
            </Button>
            <Button
              onClick={() =>
                window.open(
                  "https://sites.google.com/view/ecoalarmist-covid/covid-protocol",
                  "_blank"
                )
              }
              sx={{ flexGrow: "1", borderColor: '#73ff00' , color: '#73ff00' }}
              variant="outlined"
            >
              Covid Protocol
            </Button>
          </Grid>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            sx={{
              width: {
                lg: "30%",
                md: "40%",
                sm: "70%",
                xs: "80%",
              },
              gap: "8px",
            }}
          >
            <Button
              fullWidth
              onClick={handleWhatsappButtonClick}
              color="error"
              variant="outlined"
              startIcon={<Error />}
            >
              Can't find the exact resource?
            </Button>
            <Button
              fullWidth
              onClick={handleWhatsappButtonClick}
              color="warning"
              variant="outlined"
              startIcon={<WhatsappRounded />}
            >
              Inform missing or incorrect data
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default FindResponse;

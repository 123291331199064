import { Grid } from "@mui/material";

import "./App.css";
import AppBarMenu from "./components/AppBar/AppBarMenu";
import FindResponse from "./components/FindResponse/FindResponse";
import PopupAd from "./components/PopupAd/PopupAd";

function App() {
  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <AppBarMenu />
      <FindResponse />
      <PopupAd visibility={true} />
    </Grid>
  );
}

export default App;

import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";

import "./style.css";
import logo from "../../logo.png";

const pages = [
  { id: 0, tag: "Main Site", link: "https://www.ecoalarmist.com/" },
  { id: 1, tag: "Donate", link: "https://donate.ecoalarmist.com/" },
  { id: 1, tag: "Blog", link: "https://blog.ecoalarmist.com/" },
  {
    id: 2,
    tag: "Contact Us",
    link: "https://www.ecoalarmist.com/contact-us",
  },
];

const ResponsiveAppBar = () => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleClick = (link) => {
    window.open(link, "_blank");
  };

  return (
    <AppBar
      sx={{ bgcolor: "transparent", paddingTop: "10px", boxShadow: 'none'}}
      position="static"
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Container sx={{ mr: 2, width: 'auto', display: { xs: "none", md: "flex" } }}>
            <img className="logoWebImage" src={logo} alt="Logo" />
          </Container>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page.id} onClick={() => handleClick(page.link)}>
                  <Typography textAlign="center">{page.tag}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>

          <Container sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <img className="logoMobileImage" src={logo} alt="Logo" />
          </Container>

          <Box sx={{ flexGrow:1, justifyContent: 'end', display: { xs: "none", md: "flex" } }}>
            {pages.map((page) => (
              <Button
                key={page.id}
                onClick={() => handleClick(page.link)}
                sx={{
                  display: "block",
                }}
              >
                <Typography color={"white"} textAlign="center">
                  {page.tag}
                </Typography>
              </Button>
            ))}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default ResponsiveAppBar;

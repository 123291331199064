import React, { useEffect, useState } from "react";
import { Autocomplete, TextField } from "@mui/material";

import { useStyles } from "./style";

const Input = ({ label, data, state, setState }) => {
  const classes = useStyles();
  const [options, setOptions] = useState([]);

  useEffect(() => {
    const arr = data.map((x) => x.name);
    setOptions(arr);
  }, [data]);

  const handleInput = (value) => {
    setState(value);
  };

  return (
    <Autocomplete
      classes={classes}
      disablePortal
      value={state}
      onChange={(event, value) => handleInput(value)}
      options={options}
      sx={{
        width: {
          lg: "30%",
          md: "40%",
          sm: "70%",
          xs: "80%",
        },
      }}
      renderInput={(params) => (
        <TextField variant="outlined" {...params} label={label} />
      )}
    />
  );
};

export default Input;
